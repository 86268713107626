import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
//import ScrollToTop from './ScrollToTop';

const App = () => {

  return(
    <BrowserRouter>
      <div>
        <Router />
      </div>
    </BrowserRouter>
  )
};

export default App;