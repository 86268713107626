import urlJoin from 'url-join'
import { User } from '../entities'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()
export class UserService {

  public async search(filter: any, token: string): Promise<User[]> {
    let url = urlJoin(apiClient.getRestDefaultUri(), `/user`)

    if (filter.type) {
      url += `?type=${filter.type}`
    }

    const res = await apiClient.DoRequest('GET', url, {}, { Authorization: `Bearer ${token}` })

    const users: User[] = []
    for (const user of res.data.rows) {
      users.push(new User(user))
    }
    return users
  }

  public async get(id: number, token: string): Promise<User> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/user/${id}`)
    console.log('url', url)

    const res = await apiClient.DoRequest('GET', url, {}, { Authorization: `Bearer ${token}` })

    return new User(res.data)
  }

  public async save(user: User, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/user`)

    const method = user.id ? 'PATCH' : 'POST'
    try {
      const res = await apiClient.DoRequest(method, url, user, { Authorization: `Bearer ${token}` })
      console.log('res', res)
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }

  public async delete(user: User, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/user`)

    try {
      await apiClient.DoRequest('DELETE', url, user, { Authorization: `Bearer ${token}` })
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }

}