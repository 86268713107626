import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { AuthenticationService } from '../../../services/AuthenticationService';
import UserStorage from '../../../util/userStorage';
import { useNavigate } from 'react-router-dom';
// import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
  const navigate = useNavigate()

  const user = UserStorage.get()

  const handleHideSidebar = () => {
    onClick();
  };
  

  const logout = async () => {
    const authenticationService = new AuthenticationService()

    const token = UserStorage.getToken()
    await authenticationService.logout(token)
    UserStorage.logout()
    
    navigate('/login')
  }
  
  if (user === null || user === undefined) {
    logout()
  }

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">      
        <SidebarLink title="Dashboard" icon="list" route="/home" onClick={handleHideSidebar} />
      </ul>
      {(user?.role === 'admin' || user?.role === 'registration') &&
      <ul className="sidebar__block">      
        <SidebarLink title="Clientes" icon="license" route="/customer" onClick={handleHideSidebar} />
      </ul>
      }
      {user?.role === 'admin' &&
      <ul className="sidebar__block">
          <SidebarLink title="Usuários" icon="users" route="/user" onClick={handleHideSidebar} />
      </ul>
      }
      {user?.role === 'admin' &&
      <ul className="sidebar__block">
          <SidebarLink title="Moedas" icon="layers" route="/currency" onClick={handleHideSidebar} />
      </ul>
      }
      {user?.role === 'admin' &&
      <ul className="sidebar__block">
          <SidebarLink title="Países" icon="flag" route="/country" onClick={handleHideSidebar} />
      </ul>
      }
      {user?.role === 'admin' &&
      <ul className="sidebar__block">
          <SidebarLink title="Tipos de Envio" icon="arrow-up" route="/sendType" onClick={handleHideSidebar} />
      </ul>
      }
      <ul className="sidebar__block">
          <SidebarLink title="Pedidos" icon="cart" route="/order" onClick={handleHideSidebar} />
      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Sair" icon="exit" route="/login" onClick={logout} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
