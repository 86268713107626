import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Country } from '../../../../../entities/region/Country';
import { CountryService } from '../../../../../services/CountryService';
import UserStorage from '../../../../../util/userStorage';
import { Button, Col, Container, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';

const CountryListPage = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [countries, setCountries] = useState<Country[]>([])
  
  const countryService = new CountryService()
  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const search = async () => {
    setIsLoading(true);
    const token = UserStorage.getToken()    
    const countries = await countryService.search({}, token)
    setCountries(countries)
    setIsLoading(false);
  }

  const deleteAction = async (country: Country) => {
    if (window.confirm("Deseja realmente remover?")) {
      const token = UserStorage.getToken()    
      await countryService.delete(country, token)
      search()
    }
  }

  const renderActionButtons = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/country/${params.row.id}`)
          }}
        >
          Editar
        </Button>
        <Button
          color="danger"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            deleteAction(params.row)
          }}
        >
          Remover
        </Button>
      </div>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 300
    },
    {
      field: 'code',
      headerName: 'Código',
      width: 150
    },
    {
      field: '',
      width: 300,
      headerName: 'Ações',
      renderCell: renderActionButtons
    }
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Países</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Países</h5>
                <Button color="primary" onClick={() => navigate('/country/0')}>Novo</Button>
                <h5 className="subhead">Listagem de países</h5>
              </div>
              <div style={{ height: 530, width: '100%' }}>
                <DataGrid
                  rows={countries}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection={false}
                  disableSelectionOnClick                  
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>  
  )

}

export default CountryListPage