export class Customer {
    public id = 0;
    public email = '';
    public name = '';
    public createdAt = new Date()
    public gender = '';
    public birthDate = ''
    public motherName = '';
    public fatherName = '';

    public cpf = '';
    public rg = '';

    public sourceCountry = '';
    public citizenship = '';
    public resident = '';

    
    public phone = '';
    public cellphone = '';
    public businessPhone = '';
    
    public street = '';
    public number = '';
    public complement = '';
    public neighborhood = '';        
    public city = '';
    public state = '';
    public zipcode = '';
    
    public howMeet = '';
    public status = '';

    constructor (data: any = {}) {
      this.id = data.id || 0
      this.email = data.email ?? ''
      this.name = data.name ?? ''
      this.createdAt = new Date(data.createdAt)
      
      this.gender = data.gender ?? 'M'
      this.birthDate = data.birthDate ?? ''
      this.fatherName = data.fatherName ?? ''
      this.motherName = data.motherName ?? ''
      
      this.cpf = data.cpf ?? ''
      this.rg = data.rg ?? ''
      
      this.sourceCountry = data.sourceCountry ?? ''
      this.citizenship = data.citizenship ?? ''
      this.resident = data.resident ?? ''
      
      this.cellphone = data.cellphone ?? ''
      this.phone = data.phone ?? ''
      this.businessPhone = data.businessPhone  ?? ''

      this.street = data.street ?? ''
      this.number = data.number ?? ''
      this.complement = data.complement ?? ''
      this.neighborhood = data.neighborhood ?? ''
      this.city = data.city ?? ''
      this.state = data.state ?? ''     
      this.zipcode = data.zipcode ?? ''
      
      this.howMeet = data.howMeet ?? ''
      this.status = data.status ?? '1'
    }
}
