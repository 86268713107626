import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { UserService } from '../../../../services/UserService';
import { User } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';
import { FiArrowLeft } from 'react-icons/fi';

import './index.scss';

const ProfileFormPage = () => {
  const navigate = useNavigate();
  const params: any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User>(new User())

  const [errorMessage, setErrorMessage] = useState('')

  const userService = new UserService()
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const loadUser = async () => {
    setIsLoading(true);
    const user = UserStorage.get()
    if (user !== null) {
      setUser(user)
    }
    setIsLoading(false);
  }

  const save = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')

    if (!user.email) {
      setErrorMessage('Digite o e-mail.');
      return ''
    }

    if (!user.name) {
      setErrorMessage('Digite o nome.');
      return ''
    }

    if (user.id === '' || user.password.length > 0) {
      if (user.password.length < 6) {
        setErrorMessage('Digite uma senha.');
        return ''
      }
    }

    if (user.password.length >= 6 && user.password !== user.passwordConfirm) {
      setErrorMessage('A confirmação está diferente da senha.');
      return ''
    }
    
    if (user.balance < 0) {
      setErrorMessage('Digite um saldo maior ou igual a 0.');
      return ''
    }

    const token = UserStorage.getToken()
    await userService.save(user, token)

    navigate('/user')
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Perfil de Usuário</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="form-topside card__title">
                <button className="form-back-button" onClick={() => navigate('/customer')}><FiArrowLeft className="FiArrowLeft" /></button>
                <h5 className="bold-text">Usuário</h5>
                <h5 className="subhead">Editar usuário</h5>
              </div>
              <div className="form-error-message-container">
                <span className="form-error-message">{errorMessage}</span>
              </div>
              <Container fluid>
                <Row>
                  <Col>
                    <Form onSubmit={save}>
                      <Row>
                        <Col xxl={12}>
                          <FormGroup floating>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Nome"
                              type="text"
                              value={user.name}
                              onChange={(event) => setUser({ ...user, name: event.target.value })}
                            />
                            <Label for="name">
                              Nome
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="password"
                              name="password"
                              placeholder="Senha"
                              type="password"
                              value={user.password}
                              onChange={(event) => setUser({ ...user, password: event.target.value })}
                            />
                            <Label for="password">
                              Senha
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="passwordConfirm"
                              name="passwordConfirm"
                              placeholder="Confirme sua senha"
                              type="password"
                              value={user.passwordConfirm}
                              onChange={(event) => setUser({ ...user, passwordConfirm: event.target.value })}
                            />
                            <Label for="passwordConfirm">
                              Confirme sua senha
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                      </Row>
                      <FormGroup className="form-button-spacing">
                        <Button color="primary"type="submit">Salvar</Button>
                        <Button color="danger">Excluir</Button>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default ProfileFormPage;
