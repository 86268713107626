import urlJoin from 'url-join'
import { Beneficiary } from '../entities/beneficiary/Beneficiary'
import UserStorage from '../util/userStorage'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class BeneficiaryService {
  public async search (filter: any, token: string): Promise<Beneficiary[]> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/beneficiary`)

    const result = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    const beneficiaries: Beneficiary[] = []
    for (const beneficiary of result.data) {
      beneficiaries.push(new Beneficiary(beneficiary))
    }

    return beneficiaries
  }

  public async get (id: number, token: string): Promise<Beneficiary> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/beneficiary/${id}`)

    const result = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    return new Beneficiary(result.data)
  }

  public async add (beneficiary: Beneficiary): Promise<Beneficiary> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/beneficiary`)

    const token = await UserStorage.getToken()

    const method = 'POST'
    const data = await apiClient.DoRequest(method, url, beneficiary, { Authorization: token })

    return new Beneficiary(data.beneficiary)
}

}