import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { AuthenticationService } from '../../../services/AuthenticationService';

function ForgotPassword() {
  const authenticationService = new AuthenticationService()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const success = await authenticationService.forgotPassword(email)
    if (success === true) {
      setMessage("Em alguns instantes você receberá um e-mail com um link para recuperar a sua senha.")
      setErrorMessage("")
    } else {
      setErrorMessage('E-mail não cadastrado!')
    }

    setIsLoading(false);
    return
  }

  return (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Esqueci minha senha
          </h3>
          <h4 className="account__subhead subhead">Enviaremos um email com instruções de como redefinir sua senha</h4>
        </div>
        {isLoading &&
          <div>Recuperando...</div>
        }
        {message !== '' ?
          <div>{message}</div>
        :
        <form className="form">
          <div className="form__form-group">
            <span className="form__form-group-label">Insira seu email</span>            
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <input
                name="email"
                type="text"
                placeholder="email@exemplo.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)} 
              />
            </div>
          </div>
          <button onClick={save} type="button" className="btn btn-primary account__btn account__btn--small">Recuperar senha</button>
          <Link className="btn btn-secondary account__btn account__btn--small" to="/login">Voltar</Link>          
        </form>
      }
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword;
