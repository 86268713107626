export class Currency {
  public id = 0;
  public name = ''
  public code = ''
  public rate = 1
  public feeRate = 0
  public feeExternal = 0
  public min = 0
  public icon = ''
  public status = 1

  public iban = false
  public branchCode = false
  public accountNumber = false
  public cnaps = false
  public bsb = false
  public transit = false

  constructor (data: any = {}) {
    this.id = data.id
    this.name = data.name || ''
    this.code = data.code || ''
    this.rate = data.rate ?? 0
    this.feeRate = data.feeRate ?? 0
    this.feeExternal = data.feeExternal ?? 0
    this.min = data.min ?? 0
    this.icon = data.icon ?? ''
    this.status = data.status ?? 1

    this.iban = data.iban === true
    this.branchCode = data.branchCode === true
    this.accountNumber = data.accountNumber === true
    this.cnaps = data.cnaps === true
    this.bsb = data.bsb === true
    this.transit = data.transit === true
  }
}