import React, { useState } from 'react';

interface UploadFieldProps {
  label: string
  fieldName: string
  fileUpload: any
  imageUrl?: string
}

export default function UploadField(props: UploadFieldProps) {
  const initialImage = props.imageUrl ?? 'https://u5p0gnhcql.map.azionedge.net/Custom/Content/Themes/Base/Images/sem-foto.gif'
  console.log(initialImage)
  const [image, setImage] = useState({ preview: initialImage, data: '' })

  const changeFile = async (event: any) => {
    // event.preventDefault();
    const img = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    }
    setImage(img)

    let formData = new FormData()
    formData.append('file', img.data)

    props.fileUpload(props.fieldName, formData)
  }
  return (
    <div>
      <label>{props.label}</label>
      <br />
      {image.preview && 
        <img src={image.preview} alt={props.fieldName} width='40' height='40' />
      }    
      <input
          type="file"
          onChange={changeFile}
      />
    </div>
  )
}