import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserStorage from '../../../../../util/userStorage';
import { Button, Col, Container, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { SendType } from '../../../../../entities/beneficiary/SendType';
import { SendTypeService } from '../../../../../services/SendTypeService';

const SendTypeListPage = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [sendTypes, setSendTypes] = useState<SendType[]>([])

  const sendTypeService = new SendTypeService()
  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const search = async () => {
    setIsLoading(true);
    const token = UserStorage.getToken()    
    const sendTypes = await sendTypeService.search({}, token)
    setSendTypes(sendTypes)
    setIsLoading(false);
  }

  const deleteAction = async (sendType: SendType) => {
    if (window.confirm("Deseja realmente remover?")) {
      const token = UserStorage.getToken()    
      await sendTypeService.delete(sendType, token)
      search()
    }
  }

  const renderIsActive = (params: GridCellParams) => {
    return (
      <div>
        {params.row.isActive ?
        <span>Sim</span>
        :
        <span>Não</span>
        }
      </div>
    )
  }
  
  const renderActionButtons = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/sendType/${params.row.id}`)
          }}
        >
          Editar
        </Button>
        <Button
          color="danger"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            deleteAction(params.row)
          }}
        >
          Remover
        </Button>
      </div>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'forWhom',
      headerName: 'Para quem?',
      width: 200
    },
    {
        field: 'reason',
        headerName: 'Motivo',
        width: 400
    },
    {
      field: 'code',
      headerName: 'Código',
      width: 130
    },
    {
      field: 'tax',
      headerName: 'Imposto',
      width: 130
    },
    {
      field: 'isActive',
      width: 120,
      headerName: 'Ativo?',
      renderCell: renderIsActive
    },
    {
      field: '',
      width: 300,
      headerName: 'Ações',
      renderCell: renderActionButtons
    }
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Tipos de Envio</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Tipos de Envio</h5>
                <Button color="primary" onClick={() => navigate('/sendType/0')}>Novo</Button>
                <h5 className="subhead">Listagem de tipos de envio</h5>
              </div>
              <div style={{ height: 530, width: '100%' }}>
                <DataGrid
                  rows={sendTypes}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection={false}
                  disableSelectionOnClick                  
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>  
  )
}

export default SendTypeListPage