import { Pix } from "./Pix"

export class Transaction {
    public id = 0
    public transactionId = ''
    public value = 0
    public status = ''
    public createdAt = new Date()
    public dueDate = new Date()
    public dueDateTime = new Date()

    public pix: Pix|null = null

    constructor (data: any ={}) {
        this.id = data.id ?? 0
        this.transactionId = data.transactionId
        this.value = parseFloat(data.value)
        this.status = data.status        
        this.createdAt = new Date(data.createdAt)
        this.dueDate = new Date(data.dueDate)
        this.dueDateTime = new Date(data.dueDateTime)

        if (data.pix) {
            this.pix = new Pix(data.pix)
        }
    }
}