import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { AuthenticationService } from '../../../services/AuthenticationService';

function RecoveryPassword() {
  const authenticationService = new AuthenticationService()
  const navigate = useNavigate()
  const params: any = useParams();

  const [token, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  useEffect(() => {
    if (params.token) setToken(params.token)
  }, [params.token])

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    const success = await authenticationService.recoveryPassword(token, email, password, passwordConfirm)
    if (success === true) {
      alert("Senha alterada com sucesso.")
      navigate('/login')
    } else {
      setErrorMessage('E-mail não cadastrado!')
    }

    return
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Recuperação de Senha
            </h3>
            <h4 className="account__subhead subhead">Preencha os campos abaixo para redefinir sua senha</h4>
          </div>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Insira novamente seu email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <input
                  name="email"
                  type="text"
                  placeholder="email@exemplo.com"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)} 
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Insira sua nova senha</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder="Nova senha"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)} 
                />
                <button
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  onClick={() => handleShowPassword()}
                  type="button"
                ><EyeIcon />
                </button>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Insira novamente sua nova senha</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="confirmPassword"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder="Confirmação de nova senha"
                  value={passwordConfirm}
                  onChange={(event) => setPasswordConfirm(event.target.value)} 
                />
                <button
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  onClick={() => handleShowPassword()}
                  type="button"
                ><EyeIcon />
                </button>
              </div>
            </div>
            <button onClick={save} type="button" className="btn btn-primary account__btn account__btn--small">Redefinir senha</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RecoveryPassword;
