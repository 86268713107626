import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import UserStorage from '../../../util/userStorage';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../../../services/AuthenticationService';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const navigate = useNavigate()

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = async () => {
    const authenticationService = new AuthenticationService()

    const token = UserStorage.getToken()
    await authenticationService.logout(token)
    UserStorage.logout()
    
    navigate('/login')
  }

  const user = UserStorage.get()
  if (user === null || user === undefined) {
    logout()
  }


  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{user?.name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">      
        <div className="topbar__menu">
          <TopbarMenuLink title="Meu Perfil" icon="user" path="/profile" />
          {/* {user?.type === 'admin' && (
            <TopbarMenuLink title="Usuários" icon="inbox" path="/user" />
          )}
          {user?.type === 'analyst' && (
            <TopbarMenuLink title="Envios" icon="list" path="/customer" />
          )} */}

        <span onClick={logout}>
        <TopbarMenuLink title="Sair" icon="exit" path="/login" />
        </span>  
                    
          {/* <div className="topbar__menu-divider" />
          <TopbarMenuLink title="Log Out" icon="exit" path="/" /> */}
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
