import { Beneficiary } from "../beneficiary/Beneficiary"
import { Currency } from "../currency/Currency"
import { Customer } from "../customer/Customer"
import { Transaction } from "../transaction/Transaction"
import { OrderStatusHistory } from "./OrderHistory"
import { OrderStatus } from "./OrderStatus"

export class Order {
    public id = 0
    public customer = new Customer()
    public beneficiary = new Beneficiary()
    public valueFrom = 0
    public currencyFrom = new Currency()
    public currencyTo = new Currency()
    public exchangeRate = 0
    public exchangeRateReal = 0
    public tax = 0
    public fee = 0
    public feeExternal = 0
    public valueTo = 0
    public paid = 0
    public paidTotal = 0

    public transaction = new Transaction()

    public status = new OrderStatus()
    public statusHistories: OrderStatusHistory[] = [];
  
    constructor (data: any = {}) {
        this.id = data.id
        this.customer = new Customer(data.customer)
        this.beneficiary = new Beneficiary(data.beneficiary)
        this.valueFrom = data.valueFrom;
        this.currencyFrom = new Currency(data.currencyFrom)
        this.currencyTo = new Currency(data.currencyTo)
        this.exchangeRate = data.exchangeRate
        this.exchangeRateReal = data.exchangeRateReal
        this.tax = data.tax
        this.fee = data.fee
        this.feeExternal = data.feeExternal
        this.valueTo = data.valueTo
        this.paid = data.paid
        this.paidTotal = data.paidTotal

        if (data.transaction) {
            this.transaction = new Transaction(data.transaction)
        }

        this.status = new OrderStatus(data.status)
        if (data.histories) {
            for (const status of data.histories) {
                this.statusHistories.push(new OrderStatusHistory(status))
            }
        }
    }
  }