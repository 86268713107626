import urlJoin from 'url-join'
import { Customer } from '../entities'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class CustomerService {

  public async search (filter: any, token: string) :Promise<Customer[]> {
    let filterQuery = ''
    if (filter.search) {
      filterQuery = `filter[search]=${filter.search}`;
    }
    if (filter.status) {
      filterQuery = `filter[status]=${filter.status}`;
    }
    
    const url = urlJoin(apiClient.getRestDefaultUri(), `/customer?${filterQuery}`)
    const res = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    const customers: Customer[] = []
    for (const customer of res.data.rows) {
      customers.push(new Customer(customer))
    }
    return customers
  }

  public async get (id: number, token: string): Promise<Customer> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/customer/${id}`)

    const res = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    return new Customer(res.data)
  }

  public async save (customer: Customer, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/customer`)

    const method = customer.id ? 'PATCH' : 'POST'
    const res = await apiClient.DoRequest(method, url, customer, { Authorization: token })
    console.log('res', res)
  }

  public async uploadFile (formData: FormData, token: string) {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/customer/upload`)

    return await apiClient.DoRequest('POST', url, formData, { Authorization: token })
  }

}