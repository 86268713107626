import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { Card, CardBody } from 'reactstrap'
import { DataGrid, GridCellParams, GridColDef, GridSelectionModel, GridValueFormatterParams } from '@material-ui/data-grid'
import { Order } from '../../../../entities/order/Order'
import { OrderService } from '../../../../services/OrderService'
import UserStorage from '../../../../util/userStorage'
import { OrderStatus } from '../../../../entities/order/OrderStatus'

const OrderListPage = () => {
  const navigate = useNavigate()
  
  const user = UserStorage.get()

  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<Order[]>([])
  const [ordersSelected, setOrdersSelected] = useState([])
  const [bulkActionCode, setBulkActionCode] = useState('')
  const [filter, setFilter] = useState<any>({statusId: ''})
  const [statusList, setStatusList] = useState<OrderStatus[]>([])
  const [bulkActions, setBulkActions] = useState<any[]>([])
  const [totalSelected, setTotalSelected] = useState('Nenhum pedido selecionado.')

  const orderService = new OrderService()

  useEffect(() => {
    loadBulkActions()
    loadStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadBulkActions = () => {
    if (user?.role === 'admin') {
      setBulkActions([
        { code: 'customer-approval', name: 'Aprovar Cadastro', statusPendingCode: 'pending', statusChangeCode: "customer"  },
        { code: 'customer-reproval', name: 'Reprovar Cadastro', statusPendingCode: 'pending', statusChangeCode: "canceled" },
        { code: 'interbank', name: 'Avançar Interbancário', statusPendingCode: 'customer', statusChangeCode:  "interbank"},
        { code: 'financial', name: 'Aprovação Financeira', statusPendingCode: 'interbank', statusChangeCode: "financial" },
        { code: 'money-order', name: 'Ordem de Pagamento', statusPendingCode: 'financial', statusChangeCode: "moneyOrder" },
        { code: 'complete', name: 'Finalizar Pedido', statusPendingCode: 'moneyOrder', statusChangeCode: "complete" },
        { code: 'canceled', name: 'Cancelar', statusChangeCode: "canceled" },
      ])
    } else if (user?.role === 'registration') {
      setBulkActions([
        { code: 'customer-approval', name: 'Aprovar Cadastro', statusPendingCode: 'pending', statusChangeCode: "customer"  },
        { code: 'customer-reproval', name: 'Reprovar Cadastro', statusPendingCode: 'pending', statusChangeCode: "canceled" },
        { code: 'canceled', name: 'Cancelar', statusChangeCode: "canceled" },
      ])
    } else if (user?.role === 'interbank') {
      setBulkActions([
        { code: 'interbank', name: 'Avançar Interbancário', statusPendingCode: 'customer', statusChangeCode:  "interbank"},
        { code: 'financial', name: 'Aprovação Financeira', statusPendingCode: 'interbank', statusChangeCode: "financial" },
        { code: 'canceled', name: 'Cancelar', statusChangeCode: "canceled" },
      ])
    } else if (user?.role === 'financial') {
      setBulkActions([
        // { code: 'interbank', name: 'Avançar Interbancário', statusPendingCode: 'customer', statusChangeCode:  "interbank"},
        { code: 'financial', name: 'Aprovação Financeira', statusPendingCode: 'interbank', statusChangeCode: "financial" },
        { code: 'canceled', name: 'Cancelar', statusChangeCode: "canceled" },
      ])
    } else if (user?.role === 'money-order') {
      setBulkActions([
        { code: 'money-order', name: 'Ordem de Pagamento', statusPendingCode: 'financial', statusChangeCode: "moneyOrder" },
        { code: 'complete', name: 'Finalizar Pedido', statusPendingCode: 'moneyOrder', statusChangeCode: "complete" },
        { code: 'canceled', name: 'Cancelar', statusChangeCode: "canceled" },
      ])
    }
  }

  const loadStatus = async () => {
    const token = UserStorage.getToken()
    let status = await orderService.searchStatus({}, token)
  
    let newFilter: any
    if (user?.role === 'registration') {
      status = status.filter(status => status.code === 'pending')
      setFilter({...filter, statusId: 1})
      newFilter = {...filter, statusId: 1}
    } else if (user?.role === 'interbank') {
      status = status.filter(status => status.code === 'customer' || status.code === 'interbank')
      setFilter({...filter, statusId: 2})
      newFilter = {...filter, statusId: 2}
    } else if (user?.role === 'financial') {
      status = status.filter(status => status.code === 'customer' || status.code === 'interbank')
      setFilter({...filter, statusId: 3})
      newFilter = {...filter, statusId: 3}
    } else if (user?.role === 'money-order') {
      status = status.filter(status => status.code === 'financial' || status.code === 'moneyOrder' || status.code === 'complete')
      setFilter({...filter, statusId: 4})
      newFilter = {...filter, statusId: 4}
    }

    setStatusList(status)

    search(newFilter)
  }

  const search = async (newFilter: any = null) => {
    setIsLoading(true);
    const token = UserStorage.getToken()

    const filterToUse = newFilter !== null ? newFilter : filter

    const orders = await orderService.search(filterToUse, token)
    setOrders(orders)

    setIsLoading(false);
  }

  const applyBulkAction = async () => {
    const bulkAction = bulkActions.find(actions => actions.code === bulkActionCode)
    if (!bulkAction) return

    let paid: number|undefined = undefined

    if (bulkAction.code === 'interbank') {
      let value = window.prompt('Quanto pagou na moeda?')
      value = value ? value?.replace(',', '.') : ''
      paid = parseFloat(value)
      console.log('resp', paid)
      if (!paid || isNaN(paid)) {
        alert('Você deve digitar um número.')  
        return
      }

      const confirm = window.confirm(`Confirma que você pagou R$ ${paid} pela moeda?`)
      if (!confirm) return

    }

    for (const orderId of ordersSelected) {
      const order = orders.find((order: Order) => order.id === orderId)
      if (!order) continue

      console.log('bulk', bulkAction)
      console.log('status', order.status)
      if (!bulkAction.statusPendingCode || bulkAction.statusPendingCode === order.status.code) {
        await orderService.changeStatus(order.id, bulkAction.statusChangeCode, paid)        
      } 
      
    }

    search()
    
  }

  const renderActionButtons = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/order/${params.row.id}`)
          }}
        >
          Ver
        </Button>
      </div>
    )
  }

  const translateTransactionStatus = (code: string) => {
    if (code === 'processing') return 'Processando'
    if (code === 'authorized') return 'Autorizado'
    if (code === 'paid') return 'Pago'    
    if (code === 'waiting_payment') return 'Esperando Pagamento'
    if (code === 'pending_refund') return 'Estorno Pendente'
    if (code === 'refunded') return 'Estornado'
    if (code === 'refused') return 'Recusado'
    if (code === 'canceled') return 'Cancelado'
    if (code === 'pending') return 'Pendente'
    return code
  }

  const columns: GridColDef[] = [
    {
      field: 'customer.name',
      headerName: 'Cliente',
      valueFormatter: (params: GridValueFormatterParams) => params.row?.customer.name,
      width: 250
    },
    {
      field: 'beneficicary.beneficiary',
      headerName: 'Beneficiário',
      valueFormatter: (params: GridValueFormatterParams) => params.row?.beneficiary.beneficiary,
      width: 250
    },
    {
      field: 'currencyFrom',
      headerName: 'De',
      valueFormatter: (params: GridValueFormatterParams) => params.row?.currencyFrom.code + ' ' + params.row?.valueFrom,
      width: 150
    },
    {
      field: 'currencyTo',
      headerName: 'Para',
      valueFormatter: (params: GridValueFormatterParams) => params.row?.currencyTo.code + ' ' + params.row?.valueTo,
      width: 150
    },
    {
      field: 'status',
      headerName: 'Status',
      valueFormatter: (params: GridValueFormatterParams) => params.row?.status.internalName + ' (' + params.row?.status.externalName + ')',
      width: 280
    },
    {
      field: 'payment_status',
      headerName: 'Status Pagamento',
      valueFormatter: (params: GridValueFormatterParams) => translateTransactionStatus(params.row?.transaction?.status),
      width: 200
    },
    {
      field: '',
      width: 120,
      headerName: 'Ações',
      renderCell: renderActionButtons
    }
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Pedidos</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Pedidos</h5>
                <h5 className="subhead">Listagem de pedidos</h5>
              </div>
              <div>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="selectStatus">
                        Status:
                      </Label>
                      <Input
                        id="selectStatus"
                        name="selectStatus"
                        type="select"
                        value={filter.statusId}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const statusId = event.target.value
                          setFilter({...filter, statusId})
                        }}
                      >
                        {user?.role === 'admin' && 
                        <option value=''>Todos</option>
                        }
                        {statusList.map((status: OrderStatus) => (
                          <option key={status.id} value={status.id}>{`${status.internalName} (${status.externalName})`}</option>
                        ))}                        
                      </Input>
                    </FormGroup>  
                  </Col>
                  <Col md={2}>
                    <Button color="primary" onClick={() => search()}>Filtrar</Button>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="selectBulkAction">
                        Ação:
                      </Label>
                      <Input
                        id="selectBulkAction"
                        name="selectGender"
                        type="select"
                        value={bulkActionCode}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const code = event.target.value
                          setBulkActionCode(code) 
                        }}
                      >
                        <option value="">Selecione uma ação</option>
                        {bulkActions.map(bulkAction => (
                          <option key={bulkAction.code} value={bulkAction.code}>{bulkAction.name}</option>
                        ))}                        
                      </Input>
                    </FormGroup>  
                  </Col>
                  <Col md={2}>
                    <Button color="primary" onClick={() => applyBulkAction()}>Aplicar</Button>
                  </Col>
                  <Col md={3}>
                    <h4>Total:</h4>
                    <h3>{totalSelected}</h3>
                  </Col>
                </Row>
              </div>
              <div style={{ height: 530, width: '100%' }}>
                <DataGrid
                  rows={orders}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection={true}
                  disableSelectionOnClick            
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                  onSelectionModelChange={(selectionModel: GridSelectionModel, details: any) => {                    
                    console.log('selectionModel', selectionModel)
                    console.log('details', details)
                    setOrdersSelected(selectionModel)

                    let sameCurrency = true;
                    let currencyCode = ''
                    let total = 0
                    for (const orderId of selectionModel) {
                      console.log('orderId', orderId)
                      const order = orders.find((order: Order) => order.id === orderId)
                      console.log('order', order)
                      if (!order?.currencyTo) continue                      
                      if (!currencyCode) currencyCode = order.currencyTo.code
                      if (currencyCode !== order.currencyTo.code) {
                        sameCurrency = false
                        break
                      }
                      total += order.valueTo * 1
                    }
                    

                    if (selectionModel.length === 0) {
                      setTotalSelected('Nenhum pedido selecionado.')
                    } else if (sameCurrency === false) {
                      setTotalSelected('Pedidos com Moedas diferentes.')
                    } else {
                      setTotalSelected(`${currencyCode} ${total.toFixed(2)}`)
                    }
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>  
  )

}

export default OrderListPage