import urlJoin from 'url-join'
import { Currency } from '../entities/currency/Currency'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class CurrencyService {
  public async search (filter: any, token: string): Promise<Currency[]> {
    const url = urlJoin(apiClient.getRestDefaultUri(), '/currency')

    const result = await apiClient.DoRequest('GET', url, {})

    const currencies: Currency[] = [];
    for (const row of result.data) {
      currencies.push(new Currency(row));
    }

    return currencies
  }

  public async get (id: number, token: string): Promise<Currency> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/currency/${id}`)

    const result = await apiClient.DoRequest('GET', url, {})

    return new Currency(result.currency)
  }

  public async save(currency: Currency, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/currency`)

    const method = currency.id ? 'PATCH' : 'POST'
    try {
      const res = await apiClient.DoRequest(method, url, currency, { Authorization: `Bearer ${token}` })
      console.log('res', res)
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }

  public async delete(currency: Currency, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/currency`)

    try {
      await apiClient.DoRequest('DELETE', url, currency, { Authorization: `Bearer ${token}` })
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }
}
