import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { Oval } from 'react-loader-spinner'
import { FiArrowLeft } from 'react-icons/fi';
import { BeneficiaryService } from '../../../../../services/BeneficiaryService';
import { Beneficiary } from '../../../../../entities/beneficiary/Beneficiary';
import UserStorage from '../../../../../util/userStorage';

const BeneficiaryViewPage = () => {
  const navigate = useNavigate();
  const params:any = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [beneficiary, setBeneficiary] = useState<Beneficiary>(new Beneficiary())

  const beneficiaryService = new BeneficiaryService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      load(id)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const Beneficiary = await beneficiaryService.get(id, token);
    setBeneficiary(Beneficiary)
    setIsLoading(false);
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Pedidos</h3>
        </Col>
      </Row>
      {isLoading && (
        // <p>Carregando...</p>
        <Oval 
          height="100"
          width="100"
          color='grey'
          ariaLabel='loading'
        />
      )}
      <Row>
        <Card>
          <CardBody>
            <div className="form-topside card__title">
              <button className="form-back-button" onClick={() => navigate('/Beneficiary')}><FiArrowLeft className="FiArrowLeft" /></button>
              <h5 className="bold-text">Beneficiário</h5>
              <h5 className="subhead">Visualizar Beneficiário</h5>
            </div>
            <Container fluid>              
              <h3 className="form-title">Cliente</h3>
              <Row>
                <Col><label>Nome</label><br />{beneficiary.customer.name}</Col>
                <Col><label>E-mail</label><br />{beneficiary.customer.email}</Col>
                <Col><label>Documento</label><br />{beneficiary.customer.cpf}</Col>
              </Row>
              <h3 className="form-title">Beneficiário</h3>
              <Row>
                <Col><label>Apelido</label><br />{beneficiary.alias}</Col>
                <Col><label>E-mail</label><br />{beneficiary.beneficiary}</Col>
                <Col><label>Número da Conta</label><br />{beneficiary.accountNumber}</Col>
              </Row>
              <h3 className="form-title">Endereço</h3>
              <Row>
                <Col><label>Endereço</label><br />{beneficiary.address.address}</Col>
                <Col><label>Complemento</label><br />{beneficiary.address.complement}</Col>
                <Col><label>Cidade</label><br />{beneficiary.address.city}</Col>
                <Col><label>Estado</label><br />{beneficiary.address.state}</Col>
                <Col><label>País</label><br />{beneficiary.address.country?.name}</Col>
                <Col><label>Zipcode</label><br />{beneficiary.address.zipcode}</Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Row>
    </Container>
  )

}

export default BeneficiaryViewPage