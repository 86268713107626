export class User {
  public id = '';
  public email = '';
  public password = '';
  public passwordConfirm = '';
  public name = '';
  public role = 'admin';
  public status = 1;

  constructor(data: any = {}) {
    this.id = data.id || data._id
    this.email = data.email || ''

    if (data.password) {
      this.password = data.password
    }
    
    if (data.passwordConfirm) {
      this.passwordConfirm = data.passwordConfirm
    }
    
    
    this.name = data.name
    this.role = data.role
    this.status = parseInt(data.status) || 1
  }
}
