import urlJoin from 'url-join'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class CorreiosService {
  public async searchAddressByZipcode(zipcode: string, token: string): Promise<any> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/correios/searchAddressByZipcode/${zipcode}`)

    const address = await apiClient.DoRequest('GET', url, {}, { Authorization: `Bearer ${token}` })
    return address
  }
}
