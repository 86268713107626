import { Currency } from "../currency/Currency";
import { Customer } from "../customer/Customer";
import { Country } from "../region/Country";
import { BeneficiaryAddress } from "./BeneficiaryAddress";
import { SendType } from "./SendType";

export class Beneficiary {
  public id = 0;
  public customer: Customer = new Customer()
  public country: Country = new Country()
  public currency: Currency = new Currency()
  public alias = ''
  public beneficiary = ''
  public swift = ''
  public iban = ''
  public branchCode = ''
  public accountNumber = ''
  public cnaps = ''
  public bsb = ''
  public transit = ''
  public sendType: SendType = new SendType()
  public address: BeneficiaryAddress = new BeneficiaryAddress()


  constructor (data: any = {}) {
    this.id = data.id
    this.customer = new Customer(data.customer)
    this.alias = data.alias
    this.beneficiary = data.beneficiary
    this.swift = data.swift
    this.iban = data.iban
    this.branchCode = data.branchCode
    this.accountNumber = data.accountNumber
    this.cnaps = data.cnaps
    this.bsb = data.bsb
    this.transit = data.transit

    if (data.address) {
      this.address = new BeneficiaryAddress(data.address)
    }

    if (data.country) {
      this.country = new Country(data.country)
    }

    if (data.currency) {
      this.currency = new Currency(data.currency)
    }

    this.sendType = new SendType(data.sendType)
    

  }
}