import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../Auth/LogIn/index';
import ForgotPassword from '../Auth/ForgotPassword';
import RecoveryPassword from '../Auth/RecoveryPassword';
import CustomerListPage from '../Dashboard/customer/list';
import CustomerFormPage from '../Dashboard/customer/form';
import UsersListPage from '../Dashboard/users/list';
import UsersFormPage from '../Dashboard/users/form';
import ProfileFormPage from '../Dashboard/users/profile';
import HomePage from '../Dashboard/home/home'
import CurrencyListPage from '../Dashboard/currency/list/CurrencyListPage';
import CountryListPage from '../Dashboard/region/country/list/CountryListPage';
import CountryFormPage from '../Dashboard/region/country/form/CountryFormPage';
import SendTypeListPage from '../Dashboard/beneficiary/sendType/list/SendTypeListPage';
import SendTypeFormPage from '../Dashboard/beneficiary/sendType/form/SendTypeFormPage';
import BeneficiaryListPage from '../Dashboard/beneficiary/beneficiary/list/BeneficiaryListPage';
import OrderListPage from '../Dashboard/order/list/OrderListPage';
import OrderViewPage from '../Dashboard/order/view/OrderViewPage';
import BeneficiaryViewPage from '../Dashboard/beneficiary/beneficiary/view/BeneficiaryViewPage';
import CurrencyFormPage from '../Dashboard/currency/form/CurrencyFormPage';
import UserStorage from '../../util/userStorage';

const PrivateOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
  <Route {...rest}
      render={(props: any) =>
          UserStorage.hasToken() ?
          <Component {...props} /> 
          : 
          (props.location && props.location.pathname !== "/" ? 
              document.location.href = `/login?url=${props.location.pathname}` : 
              ''
          )
      }
  />
);

const ProtectedRoute = () => {
  if (UserStorage.hasToken() === false) {  
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

const Pages = () => (
  <Routes>    
    <Route element={<ProtectedRoute />}>
      <Route path="/home" element={<HomePage />} />
      <Route path="/customer" element={<CustomerListPage />} />
      <Route path="/customer/:id" element={<CustomerFormPage />} />
      <Route path="/user" element={<UsersListPage />} />
      <Route path="/user/:id" element={<UsersFormPage />} />
      <Route path="/profile" element={<ProfileFormPage />} />
      <Route path="/currency" element={<CurrencyListPage />} />
      <Route path="/currency/:id" element={<CurrencyFormPage />} />
      <Route path="/country" element={<CountryListPage />} />
      <Route path="/country/:id" element={<CountryFormPage />} />
      <Route path="/sendType" element={<SendTypeListPage />} />
      <Route path="/sendType/:id" element={<SendTypeFormPage />} />
      <Route path="/beneficiary" element={<BeneficiaryListPage />} />
      <Route path="/beneficiary/:id" element={<BeneficiaryViewPage />} />
      <Route path="/order" element={<OrderListPage />} />
      <Route path="/order/:id" element={<OrderViewPage />} />
    </Route>
    
  </Routes>
);

 const WrappedRoutes = () => {
   return (
   <div>
     <Layout />
     <div className="container__wrap">
      <Pages />
     </div>
   </div>
 )};

const Router = () => {
  return (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recovery-password/:token" element={<RecoveryPassword />} />
        <Route path="*" element={<WrappedRoutes />} />
      </Routes>
    </main>
  </MainWrapper>
)};

export default Router;
