import urlJoin from 'url-join'
import { Country } from '../entities/region/Country'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class CountryService {
    public async search (filter: any, token: string): Promise<Country[]> {
        const url = urlJoin(apiClient.getRestDefaultUri(), '/country')

        const result = await apiClient.DoRequest('GET', url, {})

        const countries: Country[] = [];
        for (const row of result.data) {
            countries.push(new Country(row));
        }

        return countries
    }

    public async get (id: number, token: string): Promise<Country> {
      const url = urlJoin(apiClient.getRestDefaultUri(), `/country/${id}`)
  
      const result = await apiClient.DoRequest('GET', url, {})
  
      return new Country(result.country)
    }

    public async save(country: Country, token: string): Promise<void> {
      const url = urlJoin(apiClient.getRestDefaultUri(), `/country`)
  
      const method = country.id ? 'PATCH' : 'POST'
      try {
        const res = await apiClient.DoRequest(method, url, country, { Authorization: `Bearer ${token}` })
        console.log('res', res)
      } catch (error: any) {
        console.log('error', error?.response?.data.messages)
        if (error?.response?.data?.messages) {
          throw new Error(error.response.data.messages[0].message)
        } else {
          throw error
        }
      }
    }
  
    public async delete(country: Country, token: string): Promise<void> {
      const url = urlJoin(apiClient.getRestDefaultUri(), `/country`)
  
      try {
        await apiClient.DoRequest('DELETE', url, country, { Authorization: `Bearer ${token}` })
      } catch (error: any) {
        console.log('error', error?.response?.data.messages)
        if (error?.response?.data?.messages) {
          throw new Error(error.response.data.messages[0].message)
        } else {
          throw error
        }
      }
    }
}
