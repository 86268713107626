import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { UserService } from '../../../../services/UserService';
import { User } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';

const UsersListPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState<User[]>([])

  const userService = new UserService()
  useEffect(() => {
    searchUsers({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchUsers = async (filter: any = {}) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const usersData = await userService.search(filter, token)
    setUsers(usersData)
    setIsLoading(false);
  }

  const renderUpdateButton = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/user/${params.row.id}`)
          }}
        >
          Editar
        </Button>
      </div>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 400
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 400,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200
    },
    {
      field: '',
      width: 300,
      headerName: 'Ações',
      renderCell: renderUpdateButton,
    },
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Usuários</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Usuários</h5>
                <Button color="primary" onClick={() => navigate('/user/0')}>Novo</Button>
                <h5 className="subhead">Listagem de usuários</h5>
              </div>
              <div style={{ height: 530, width: '100%' }}>
                <DataGrid
                  rows={users}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection
                  disableSelectionOnClick
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default UsersListPage;
