import jwt from 'jsonwebtoken';
import { User } from '../entities';

const storageKey = "@user";

class UserStorage { 
    static hasToken = ():boolean => { 
        var token = localStorage.getItem(storageKey);
        return !!token;
    };

    static getToken = ():string => {
        const token = localStorage.getItem(storageKey);
        return token || "";
    };

    static get = ():User|null => {
        const token = UserStorage.getToken();
        if (token === null) 
            return null;

        const data:any = jwt.decode(token);
        return data ? new User(data.user) : null;
    };

    static setToken = (userToken:string) => {
        localStorage.setItem(storageKey, userToken);
    };

    static logout = () => {
        localStorage.removeItem(storageKey);
    };    
}

export default UserStorage;