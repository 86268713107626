export class Pix {
    public qrCodeBase64 = ''
    public qrCodeImage = ''
    public emv = ''
    public bacenUrl = ''
    public pixUrl = ''

    constructor (data: any = {}) {
        this.qrCodeBase64 = data.qrCodeBase64
        this.qrCodeImage = data.qrCodeImage
        this.emv = data.emv
        this.bacenUrl = data.bacenUrl
        this.pixUrl = data.pixUrl
    }
}