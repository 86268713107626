import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Country } from '../../../../../entities/region/Country';
import { CountryService } from '../../../../../services/CountryService';
import UserStorage from '../../../../../util/userStorage';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Oval } from 'react-loader-spinner'
import { FiArrowLeft } from 'react-icons/fi';
import { Currency } from '../../../../../entities/currency/Currency';
import { CurrencyService } from '../../../../../services/CurrencyService';

const CountryFormPage = () => {
  const navigate = useNavigate();
  const params:any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [country, setCountry] = useState<Country>(new Country())
  const [currencies, setCurrencies] = useState<Currency[]>([])

  const [errorMessage, setErrorMessage] = useState('')

  const countryService = new CountryService()
  const currencyService = new CurrencyService()

  useEffect(() => {
    loadCurrencies()
    const id = parseInt(params.id)
    if (id && id !== 0) {
      load(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true)
    
    const token = UserStorage.getToken()
    const country = await countryService.get(id, token)
    setCountry(country)

    setIsLoading(false)
  }

  const loadCurrencies = async () => {
    setIsLoading(true)

    const token = UserStorage.getToken()
    const currencies = await currencyService.search({}, token)
    setCurrencies(currencies)

    setIsLoading(false)
  }

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault()
    setErrorMessage('')

    if (country.name.length < 3) {
      setErrorMessage('Digite o nome.');
      return ''
    }

    if (country.code.length !== 3) {
      setErrorMessage('Digite o código.');
      return ''
    }

    const token = UserStorage.getToken()
    try {
      await countryService.save(country, token)

      navigate('/country')
    } catch (error: any) {
      if (error.response.data?.errors) {
        let message = '';
        for (const errorItem of error.response.data.errors) {
          message += `${errorItem.message} \n`
        }
        setErrorMessage(message)
      }
      console.log('error', )
    }

  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Países</h3>
        </Col>
      </Row>
      {isLoading && (
        // <p>Carregando...</p>
        <Oval 
          height="100"
          width="100"
          color='grey'
          ariaLabel='loading'
        />
      )}
      <Card>
        <CardBody>
          <div className="form-topside card__title">  
            <button className="form-back-button" onClick={() => navigate('/country')}><FiArrowLeft className="FiArrowLeft" /></button>
            <h5 className="bold-text">Países</h5>
            <h5 className="subhead">Cadastrar país</h5>
          </div>
          <div className="form-error-message-container">
            <span className="form-error-message">{errorMessage}</span>
          </div>
          <Container fluid>
            <Form onSubmit={save}>
              <Row>
                <Col xl={3}>
                  <FormGroup floating>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Nome"
                      type="text"
                      value={country.name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCountry({...country, name: value})
                      }}
                    />
                    <Label for="fullName">
                      Nome
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="code"
                      name="code"
                      placeholder="Código"
                      type="text"
                      value={country.code}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCountry({...country, code: value})
                      }}
                    />
                    <Label for="fullName">
                      Código
                    </Label>
                  </FormGroup>
                </Col>                
              </Row>              
              <h3>Moedas</h3>
              <Row>
                {currencies.map((currency: Currency) => (
                  <Col xl={1} key={`currency-${currency.id}`}>                    
                    <FormGroup check>
                      <Label>
                        <Input
                          id={`currency-${currency.id}`}
                          name={`currency-${currency.id}`}
                          type='checkbox'
                          checked={country.currencies.find((c: Currency) => c.id === currency.id) ? true : false}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            
                            const idx = country.currencies.findIndex((c: Currency) => c.id === currency.id)
                            const currencies = country.currencies
                            if (idx >= 0) {
                              currencies.splice(idx, 1)                              
                            } else {
                              currencies.push(currency)
                            }
                            setCountry({...country, currencies})
                          }}
                        />
                        {currency.name}
                      </Label>
                    </FormGroup>
                  </Col>
                ))}                
              </Row>
              <Row>
                <Col>
                  <FormGroup className="form-button-spacing">
                    <Button color="primary"type="submit">Salvar</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>            
          </Container>
        </CardBody>
      </Card>
    </Container>
  )

}

export default CountryFormPage