import urlJoin from 'url-join'
import { SendType } from '../entities/beneficiary/SendType'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class SendTypeService {
  public async search (filter: any, token: string): Promise<SendType[]> {
    const url = urlJoin(apiClient.getRestDefaultUri(), '/sendType')

    const result = await apiClient.DoRequest('GET', url, {})

    const sendTypes: SendType[] = [];
    for (const row of result.data) {
      sendTypes.push(new SendType(row));
    }

    return sendTypes
  }

  public async get (id: number, token: string): Promise<SendType> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/sendType/${id}`)

    const result = await apiClient.DoRequest('GET', url, {})

    return new SendType(result.sendType)
  }

  public async save(sendType: SendType, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/sendType`)

    const method = sendType.id ? 'PATCH' : 'POST'
    try {
      const res = await apiClient.DoRequest(method, url, sendType, { Authorization: `Bearer ${token}` })
      console.log('res', res)
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }

  public async delete(sendType: SendType, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/sendType`)

    try {
      await apiClient.DoRequest('DELETE', url, sendType, { Authorization: `Bearer ${token}` })
    } catch (error: any) {
      console.log('error', error?.response?.data.messages)
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }


}
