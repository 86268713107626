import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SendType } from '../../../../../entities/beneficiary/SendType';
import { SendTypeService } from '../../../../../services/SendTypeService';
import UserStorage from '../../../../../util/userStorage';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Oval } from 'react-loader-spinner'
import { FiArrowLeft } from 'react-icons/fi';

const SendTypeFormPage = () => {
  const navigate = useNavigate();
  const params:any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [sendType, setSendType] = useState<SendType>(new SendType())

  const [errorMessage, setErrorMessage] = useState('')

  const sendTypeService = new SendTypeService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      load(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const sendType = await sendTypeService.get(id, token);
    console.log(sendType)
    setSendType(sendType)
    setIsLoading(false);
  }

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')

    if (sendType.forWhom.length <= 0) {
      setErrorMessage('Digite para quem é o envio.');
      return ''
    }

    if (sendType.reason.length < 3) {
      setErrorMessage('Digite o motivo.');
      return ''
    }

    if (sendType.code === 0) {
      setErrorMessage('Digite o código.');
      return ''
    }

    if (sendType.tax === 0) {
      setErrorMessage('Digite o valor do imposto.');
      return ''
    }

    const token = UserStorage.getToken()
    try {
      await sendTypeService.save(sendType, token)

      navigate('/sendType')
    } catch (error: any) {
      if (error.response.data?.errors) {
        let message = '';
        for (const errorItem of error.response.data.errors) {
          message += `${errorItem.message} \n`
        }
        setErrorMessage(message)
      }
      console.log('error', )
    }

  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Tipos de Envio</h3>
        </Col>
      </Row>
      {isLoading && (
        // <p>Carregando...</p>
        <Oval 
          height="100"
          width="100"
          color='grey'
          ariaLabel='loading'
        />
      )}
      <Card>
        <CardBody>
          <div className="form-topside card__title">  
            <button className="form-back-button" onClick={() => navigate('/sendType')}><FiArrowLeft className="FiArrowLeft" /></button>
            <h5 className="bold-text">Tipos de Envio</h5>
            <h5 className="subhead">Cadastrar tipo de envio</h5>
          </div>
          <div className="form-error-message-container">
            <span className="form-error-message">{errorMessage}</span>
          </div>
          <Container fluid>
            <Form onSubmit={save}>
              <Row>
                <Col lg={2}>
                    <FormGroup>
                      <Label
                        for="forWhom"
                      >
                        Status:
                      </Label>
                      <Input
                        id="forWhom"
                        name="forWhom"
                        type="select"
                        value={sendType.forWhom}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const value = event.target.value
                          setSendType({...sendType, forWhom: value})
                        }}
                      >
                        <option value={'me'}>Eu mesmo</option>
                        <option value={'other'}>Outros</option>
                      </Input>
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <FormGroup floating>
                    <Input
                      id="reason"
                      name="reason"
                      placeholder="Motivo"
                      type="text"
                      value={sendType.reason}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setSendType({...sendType, reason: value})
                      }}
                    />
                    <Label for="reason">
                      Motivo
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={2}>
                  <FormGroup floating>
                    <Input
                      id="code"
                      name="code"
                      placeholder="Código"
                      type="number"
                      value={sendType.code}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseFloat(event.target.value)
                        setSendType({...sendType, code: value})
                      }}
                    />
                    <Label for="code">
                      Código
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={2}>
                  <FormGroup floating>
                    <Input
                      id="tax"
                      name="tax"
                      placeholder="Imposto"
                      type="number"
                      value={sendType.tax}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseFloat(event.target.value)
                        setSendType({...sendType, tax: value})
                      }}
                    />
                    <Label for="tax">
                      Imposto
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={2}>
                  <FormGroup>
                    <Label
                      for="isActive"
                    >
                      Status:
                    </Label>
                    <Input
                      id="isActive"
                      name="isActive"
                      type="select"
                      value={sendType.isActive ? '1' : '0'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value === '1'
                        setSendType({...sendType, isActive: value})
                      }}
                    >
                      <option value={0}>Inativo</option>
                      <option value={1}>Ativo</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="form-button-spacing">
                    <Button color="primary"type="submit">Salvar</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>            
          </Container>
        </CardBody>
      </Card>
    </Container>
  )

}

export default SendTypeFormPage