import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Order } from '../../../../entities/order/Order';
import { OrderService } from '../../../../services/OrderService';
import UserStorage from '../../../../util/userStorage';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { Oval } from 'react-loader-spinner'
import { FiArrowLeft } from 'react-icons/fi';
import { OrderStatusHistory } from '../../../../entities/order/OrderHistory';

import './styles.scss';

const OrderViewPage = () => {
  const navigate = useNavigate();
  const params:any = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState<Order>(new Order())

  const orderService = new OrderService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      load(id)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const order = await orderService.get(id, token);
    console.log('order', order)
    setOrder(order)
    setIsLoading(false);
  }

  const translateTransactionStatus = (code: string) => {
    if (code === 'processing') return 'Processando'
    if (code === 'authorized') return 'Autorizado'
    if (code === 'paid') return 'Pago'    
    if (code === 'waiting_payment') return 'Esperando Pagamento'
    if (code === 'pending_refund') return 'Estorno Pendente'
    if (code === 'refunded') return 'Estornado'
    if (code === 'refused') return 'Recusado'
    if (code === 'canceled') return 'Cancelado'
    if (code === 'pending') return 'Pendente'
    return code
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Pedidos</h3>
        </Col>
      </Row>
      {isLoading && (
        // <p>Carregando...</p>
        <Oval 
          height="100"
          width="100"
          color='grey'
          ariaLabel='loading'
        />
      )}
      <Row>
        <Card>
          <CardBody>
            <div className="form-topside card__title">
              <button className="form-back-button" onClick={() => navigate('/order')}><FiArrowLeft className="FiArrowLeft" /></button>
              <h5 className="bold-text">Pedidos</h5>
              <h5 className="subhead">Visualizar Pedidos</h5>
            </div>
            <Container fluid>
              <h3 className="form-title">Cliente</h3>
              <Row>
                <Col><label>Nome</label><br />{order.customer.name}</Col>
                <Col><label>E-mail</label><br />{order.customer.email}</Col>
                <Col><label>CPF</label><br />{order.customer.cpf}</Col>
                <Col><label>RG</label><br />{order.customer.rg}</Col>
              </Row>
              <h3 className="form-title">Beneficiário</h3>
              <Row>
                <Col><label>Apelido</label><br />{order.beneficiary.alias}</Col>
                <Col><label>Beneficiário</label><br />{order.beneficiary.beneficiary}</Col>                
              </Row>
              <h4>Endereço</h4>
              <Row>
                <Col><label>Endereço</label><br />{order.beneficiary?.address?.address}</Col>
                <Col><label>Complemento</label><br />{order.beneficiary?.address?.complement}</Col>
                <Col><label>Cidade</label><br />{order.beneficiary?.address?.city}</Col>
                <Col><label>Estado</label><br />{order.beneficiary?.address?.state}</Col>
                <Col><label>País</label><br />{order.beneficiary?.address?.country?.name}</Col>
                <Col><label>CEP</label><br />{order.beneficiary?.address?.zipcode}</Col>
              </Row>
              <h4>Dados Bancários</h4>
              <Row>
                <Col><label>Swift</label><br />{order.beneficiary.swift}</Col>
                <Col><label>Iban</label><br />{order.beneficiary.iban}</Col>
                <Col><label>Agência</label><br />{order.beneficiary.branchCode}</Col>
                <Col><label>Conta</label><br />{order.beneficiary.accountNumber}</Col>
                <Col><label>CNAPS</label><br />{order.beneficiary.cnaps}</Col>
                <Col><label>BSB</label><br />{order.beneficiary.bsb}</Col>
                <Col><label>Transit</label><br />{order.beneficiary.transit}</Col>
              </Row>
              <h3 className="form-title">Pedido</h3>
              <Row>
                <Col><label>De</label><br />{order.currencyFrom?.code} {order.valueFrom}</Col>
                <Col><label>Para</label><br />{order.currencyTo?.code} {order.valueTo}</Col>
                <Col><label>Câmbio</label><br />{order.exchangeRate}</Col>
                <Col><label>Câmbio Real</label><br />{order.exchangeRateReal}</Col>
                <Col><label>Imposto</label><br />{order.tax}</Col>
                <Col><label>Taxa</label><br />{order.fee}</Col>
                <Col><label>Taxa Externa</label><br />{order.feeExternal}</Col>
                <Col><label>Pago</label><br />{order.paid}</Col>
                <Col><label>Total Pago</label><br />{order.paidTotal}</Col>
              </Row>
              <h3 className="form-title">Histórico</h3>
              {order.statusHistories.map((history: OrderStatusHistory) => (
                <Row key={history.id}>
                  <Col md={2}>
                    <span>Status: {history.status.internalName}</span>
                  </Col>
                  <Col md={2}>
                    <span>Altedado em: {history.createdAt.toLocaleDateString()} {history.createdAt.toLocaleTimeString()}</span>
                  </Col>
                </Row>
              ))}
            </Container>
          </CardBody>
        </Card>
      </Row>
    </Container>
  )

}

export default OrderViewPage