import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Currency } from '../../../../entities/currency/Currency';
import { CurrencyService } from '../../../../services/CurrencyService';
import UserStorage from '../../../../util/userStorage';
import { Button, Col, Container, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { DataGrid, GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';


const CurrencyListPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)
  const [currencies, setCurrencies] = useState<Currency[]>([])
  
  const currencyService = new CurrencyService()
  useEffect(() => {
    search()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const search = async () => {
    setIsLoading(true);
    const token = UserStorage.getToken()    
    const currencies = await currencyService.search({}, token)
    setCurrencies(currencies)
    setIsLoading(false);
  }

  const deleteAction = async (currency: Currency) => {
    if (window.confirm("Deseja realmente remover a moeda?")) {
      const token = UserStorage.getToken()    
      await currencyService.delete(currency, token)
      search()
    }
  }

  const renderActionButtons = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/currency/${params.row.id}`)
          }}
        >
          Editar
        </Button>
        <Button
          color="danger"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            deleteAction(params.row)
          }}
        >
          Remover
        </Button>
      </div>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 300
    },
    {
      field: 'code',
      headerName: 'Código',
      width: 150
    },
    {
      field: 'rate',
      headerName: 'Câmbio',
      width: 150
    },
    {
      field: 'feeRate',
      headerName: 'Cobertura',
      width: 150
    },
    {
      field: 'feeExternal',
      headerName: 'Taxa Externa',
      width: 180
    },
    {
      field: 'min',
      headerName: 'Mínimo',
      width: 150
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => params.row?.status === 1 ? 'Ativo' : 'Inativo'
    },
    {
      field: '',
      width: 300,
      headerName: 'Ações',
      renderCell: renderActionButtons
    }
  ];

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Moedas</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Moedas</h5>
                <Button color="primary" onClick={() => navigate('/currency/0')}>Novo</Button>
                <h5 className="subhead">Listagem de moedas</h5>
              </div>
              <div style={{ height: 530, width: '100%' }}>
                <DataGrid
                  rows={currencies}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection={false}
                  disableSelectionOnClick                  
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>  
  )
}

export default CurrencyListPage