import { OrderStatus } from "./OrderStatus";

export class OrderStatusHistory {
  public id = 0  
  public status: OrderStatus
  public comment: string
  public createdAt: Date
  public updatedAt: Date

  constructor (data: any = {}) {
    this.id = data.id
    this.status = data.status
    this.comment = data.comment
    this.createdAt = new Date(data.createdAt)
    this.updatedAt = new Date(data.updatedAt)
  }
}