import React, { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { AuthenticationService } from '../../../services/AuthenticationService';
import UserStorage from '../../../util/userStorage';
import { useNavigate } from 'react-router-dom';

function LogIn() {
  const authenticationService = new AuthenticationService()
  const navigate = useNavigate();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const [data, setData] = useState({
    'email': '',
    'password': ''
  })
  const [errorMessage, setErrorMessage] = useState('')

  const login = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')

    console.log('data', data)
    if (!data.email) {
      setErrorMessage('Digite o e-mail');
      return
    }

    if (!data.password) {
      setErrorMessage('Digite a senha');
      return
    }

    try {
      const token = await authenticationService.login(data.email, data.password)
      UserStorage.setToken(token);
      
      navigate('/home')
    } catch (error: any) {
      console.log(error.message)
      setErrorMessage(error.message)
    }
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Bem-vindo à Broker</h3>
            <h4 className="account__subhead subhead">Corretora de Câmbio</h4>
          </div>
          <p>{errorMessage}</p>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">E-mail</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <input
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  onChange={(event) => setData({...data, email: event.target.value})}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Senha</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder="Senha"
                  onChange={(event) => setData({...data, password: event.target.value})}
                />
                <button
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  onClick={() => handleShowPassword()}
                  type="button"
                ><EyeIcon />
                </button>
              </div>
              <div className="account__forgot-password">
                <a href="/forgot-password">Esqueceu a Senha?</a>
              </div>
            </div>
            <button className="btn btn-primary account__btn account__btn--small" onClick={login}>Entrar</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LogIn;