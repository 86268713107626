import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Currency } from '../../../../entities/currency/Currency';
import { CurrencyService } from '../../../../services/CurrencyService';
import UserStorage from '../../../../util/userStorage';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Oval } from 'react-loader-spinner'
import { FiArrowLeft } from 'react-icons/fi';

const CurrencyFormPage = () => {
  const navigate = useNavigate();
  const params:any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [currency, setCurrency] = useState<Currency>(new Currency())

  const [errorMessage, setErrorMessage] = useState('')

  const currencyService = new CurrencyService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      load(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const currency = await currencyService.get(id, token);
    setCurrency(currency)
    setIsLoading(false);
  }

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')


    if (currency.name.length < 3) {
      setErrorMessage('Digite o nome.');
      return ''
    }

    if (currency.code.length !== 3) {
      setErrorMessage('Digite o código.');
      return ''
    }

    if (currency.rate < 0) {
      setErrorMessage('A taxa deve ser maior ou igual a 0.');
      return ''
    }

    if (currency.feeRate < 0) {
      setErrorMessage('A cobertura deve ser maior ou igual a 0.');
      return ''
    }

    if (currency.feeExternal < 0) {
      setErrorMessage('A taxa externa deve ser maior ou igual a 0.');
      return ''
    }

    if (currency.min < 0) {
      setErrorMessage('A mínimo deve ser maior ou igual a 0.');
      return ''
    }
    
    const token = UserStorage.getToken()
    try {
      await currencyService.save(currency, token)

      navigate('/currency')
    } catch (error: any) {
      if (error.response.data?.errors) {
        let message = '';
        for (const errorItem of error.response.data.errors) {
          message += `${errorItem.message} \n`
        }
        setErrorMessage(message)
      }
      console.log('error', )
    }

  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Moedas</h3>
        </Col>
      </Row>
      {isLoading && (
        // <p>Carregando...</p>
        <Oval 
          height="100"
          width="100"
          color='grey'
          ariaLabel='loading'
        />
      )}
      <Card>
        <CardBody>
          <div className="form-topside card__title">  
            <button className="form-back-button" onClick={() => navigate('/currency')}><FiArrowLeft className="FiArrowLeft" /></button>
            <h5 className="bold-text">Moedas</h5>
            <h5 className="subhead">Cadastrar moeda</h5>
          </div>
          <div className="form-error-message-container">
            <span className="form-error-message">{errorMessage}</span>
          </div>
          <Container fluid>
            <Form onSubmit={save}>
              <Row>
                <Col xl={3}>
                  <FormGroup floating>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Nome"
                      type="text"
                      value={currency.name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, name: value})
                      }}
                    />
                    <Label for="fullName">
                      Nome
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="code"
                      name="code"
                      placeholder="Código"
                      type="text"
                      value={currency.code}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, code: value})
                      }}
                    />
                    <Label for="fullName">
                      Código
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="rate"
                      name="rate"
                      placeholder="Câmbio"
                      type="number"
                      value={currency.rate}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, rate: value})
                      }}
                    />
                    <Label for="fullName">
                      Câmbio
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="feeRate"
                      name="feeRate"
                      placeholder="Cobertura"
                      type="number"
                      value={currency.feeRate}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, feeRate: value})
                      }}
                    />
                    <Label for="fullName">
                      Cobertura
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="feeExternal"
                      name="feeExternal"
                      placeholder="Taxa Externa"
                      type="number"
                      value={currency.feeExternal}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, feeExternal: value})
                      }}
                    />
                    <Label for="fullName">
                      Taxa Externa
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="min"
                      name="min"
                      placeholder="Mínimo"
                      type="number"
                      value={currency.min}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, min: value})
                      }}
                    />
                    <Label for="fullName">
                      Mínimo
                    </Label>
                  </FormGroup>
                </Col>
                <Col xl={1}>
                  <FormGroup floating>
                    <Input
                      id="icon"
                      name="icon"
                      placeholder="Ícone"
                      type="text"
                      value={currency.icon}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setCurrency({...currency, icon: value})
                      }}
                    />
                    <Label for="fullName">
                      Ícone
                    </Label>
                  </FormGroup>
                </Col>
                <Row>
                  <Col lg={1}>
                    <FormGroup>
                      <Label
                        for="status"
                      >
                        Status:
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        value={currency.status}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const value = parseInt(event.target.value)
                          setCurrency({...currency, status: value})
                        }}
                      >
                        <option value={0}>Inativo</option>
                        <option value={1}>Ativo</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <h3>Campos</h3>
                <Row>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="iban"
                            name='iban'
                            placeholder='Iban'
                            type='checkbox'
                            checked={currency.iban}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, iban: currency.iban === false})
                            }}
                          />
                          Iban
                        </Label>
                      </FormGroup>
                  </Col>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="accountNumber"
                            name='accountNumber'
                            placeholder='accountNumber'
                            type='checkbox'
                            checked={currency.accountNumber}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, accountNumber: currency.accountNumber === false})
                            }}
                          />
                          Account Number
                        </Label>
                      </FormGroup>
                  </Col>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="branchCode"
                            name='branchCode'
                            placeholder='Branch Code'
                            type='checkbox'
                            checked={currency.branchCode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, branchCode: currency.branchCode === false})
                            }}
                          />
                          Branch Code
                        </Label>
                      </FormGroup>
                  </Col>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="cnaps"
                            name='cnaps'
                            placeholder='cnaps'
                            type='checkbox'
                            checked={currency.cnaps}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, cnaps: currency.cnaps === false})
                            }}
                          />
                          CNAPS
                        </Label>
                      </FormGroup>
                  </Col>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="bsb"
                            name='bsb'
                            placeholder='BSB'
                            type='checkbox'
                            checked={currency.bsb}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, bsb: currency.bsb === false})
                            }}
                          />
                          BSB
                        </Label>
                      </FormGroup>
                  </Col>
                  <Col xl={1}>                    
                      <FormGroup check>
                        <Label>
                          <Input
                            id="transit"
                            name='transit'
                            placeholder='Transit'
                            type='checkbox'
                            checked={currency.transit}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setCurrency({...currency, transit: currency.transit === false})
                            }}
                          />
                          Transit
                        </Label>
                      </FormGroup>
                  </Col>                
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="form-button-spacing">
                      <Button color="primary"type="submit">Salvar</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Row>
            </Form>            
          </Container>
        </CardBody>
      </Card>
    </Container>
  )

}

export default CurrencyFormPage