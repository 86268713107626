import { Country } from "../region/Country"

export class BeneficiaryAddress {
  public id = 0
  public country?: Country
  public zipcode = 0
  public address = ''
  public complement = ''
  public city = ''
  public state = ''

  constructor (data: any = {}) {
    this.id = data.id
    this.country = new Country(data.country)
    this.zipcode = data.zipcode
    this.address = data.address
    this.complement = data.complement
    this.city = data.city
    this.state = data.state
  }
}