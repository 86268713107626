export class OrderStatus {
  public id = 0;
  public code = '';
  public position = 0;
  public internalName = '';
  public externalName = '';
  public notify = false;

  constructor (data: any = {}) {
    this.id = data.id
    this.code = data.code
    this.position = 0
    this.internalName = data.internalName
    this.externalName = data.externalName
    this.notify = data.notify === true
  }
}