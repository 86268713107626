import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { CustomerService } from '../../../../services/CustomerService';
import { Customer } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';
import { FiArrowLeft } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import { Oval } from 'react-loader-spinner'
import FsLightbox from "fslightbox-react";
import ImageViewer from 'react-simple-image-viewer';
import { Swiper, SwiperSlide } from 'swiper/react';


import './index.scss';
import { CorreiosService } from '../../../../services/CorreiosService';
import UploadField from '../../../../components/form/upload-field/UploadField';

const CustomerFormPage = () => {
  const navigate = useNavigate();
  const params:any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingZipcode, setIsLoadingZipcode] = useState(false)
  const [customer, setCustomer] = useState<Customer>(new Customer())
  const [cpfImageOpened, setCpfImageOpened] = useState(false);

  const [errorMessage, setErrorMessage] = useState('')

  const customerService = new CustomerService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      loadCustomer(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const loadCustomer = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const customerData = await customerService.get(id, token);
    setCustomer(customerData)
    setIsLoading(false);
  }

  const fileUpload = async (fieldName: string, formData: FormData) => {
    const token = UserStorage.getToken()
    const id = UserStorage.get()?.id ?? ''    
    formData.append('id', id)
    formData.append('fieldName', fieldName)

    const customerService = new CustomerService()
    const response = await customerService.uploadFile(formData, token)
    if (response.success === true) {
      setCustomer({...customer, [fieldName]: response.fileName})
    }
  }

  const save = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')

    if (!customer.email) {
      setErrorMessage('Digite o e-mail.');
      return ''
    }

    if (!customer.name) {
      setErrorMessage('Digite o nome.');
      return ''
    }
    
    const token = UserStorage.getToken()

    try {
      await customerService.save(customer, token)

      navigate('/customer')
    } catch (error: any) {
      if (error.response.data?.errors) {
        let message = '';
        for (const errorItem of error.response.data.errors) {
          message += `${errorItem.message} \n`
        }
        setErrorMessage(message)
      }
      console.log('error', )
    }
  }
  
  return (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Cadastro de Clientes</h3>
      </Col>
    </Row>
    {isLoading && (
      // <p>Carregando...</p>
      <Oval 
        height="100"
        width="100"
        color='grey'
        ariaLabel='loading'
      />
    )}
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="form-topside card__title">

              {/* <div className="confirm">
                <div className="confirm__window">
                  <div className="confirm__titlebar">
                    <span className="confirm__title">Excluir Cliente</span>
                    <button className="confirm__close">&times;</button>
                  </div>
                  <div className="confirm__content">Este cliente será excluído. Você tem certeza que deseja continuar?</div>
                  <div className="confirm__buttons">
                    <button className="confirm__button confirm__button--ok confirm__button--fill">Sim</button>
                    <button className="confirm__button confirm__button--cancel">Cancelar</button>
                  </div>
                </div>
              </div> */}

              <button className="form-back-button" onClick={() => navigate('/customer')}><FiArrowLeft className="FiArrowLeft" /></button>
              <h5 className="bold-text">Clientes</h5>
              <h5 className="subhead">Cadastrar cliente</h5>
            </div>
            <div className="form-error-message-container">
              <span className="form-error-message">{errorMessage}</span>
            </div>
            <Container fluid>
              <Row>
                <Col>
                  <Form onSubmit={save}>
                    <Row>
                      <Col xxl={12}>
                        <h3 className="form-title">Dados Pessoais</h3>
                        <Row>
                          <Col xxl={6}>
                            <FormGroup floating>
                              <Input
                                id="name"
                                name="name"
                                placeholder="Nome completo"
                                type="text"
                                value={customer.name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, name: value})
                                }}
                              />
                              <Label for="fullName">
                                Nome completo
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>                        
                          <Col xxl={6}>
                            <FormGroup floating>
                              <Input
                                id="email"
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={customer.email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, email: value})
                                }}
                              />
                              <Label for="Email">
                                Email
                              </Label>
                            </FormGroup>
                          {' '}                                                                              
                          </Col>                          
                        </Row>
                        <Row>
                          <Col xxl={3}>
                            <FormGroup>
                              <Label
                                for="selectGender"
                              >
                                Gênero:
                              </Label>
                              <Input
                                id="selectGender"
                                name="selectGender"
                                type="select"
                                value={customer.gender}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, email: value})
                                }}
                              >
                                <option value="M">Masculino</option>
                                <option value="F">Feminino</option>
                                <option value="O">Outro</option>
                              </Input>
                            </FormGroup>                          
                          </Col>
                          <Col xxl={3}>
                            <FormGroup>
                              <Label for="birthDate">
                                Data de nascimento
                              </Label>                 
                              <Input
                                id="birthDate"
                                name="birthDate"
                                placeholder="Data de nascimento"
                                type="date"
                                value={customer.birthDate}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, birthDate: value})
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xxl={6}>
                            <FormGroup floating>
                              <Input
                                id="motherName"
                                name="motherName"
                                placeholder="Nome da mãe"
                                type="text"
                                value={customer.motherName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, motherName: value})
                                }}
                              />
                              <Label for="motherName">
                                Nome da mãe
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={4}>
                            <FormGroup>
                              <Label
                                for="nacionality"
                              >
                                Nacionalidade:
                              </Label>
                              <Input
                                id="nacionality"
                                name="nacionality"
                                type="select"
                              >
                                <option>Brasileira</option>
                                <option>Norte Americana</option>
                                <option>Japonesa</option>
                                <option>Italiana</option>
                                <option>Alemã</option>
                                <option>Francesa</option>
                                <option>Espanhola</option>
                                <option>Portuguesa</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xxl={4}>
                            <FormGroup>
                              <Label
                                for="sourceCountry"
                              >
                                País de origem:
                              </Label>
                              <Input
                                id="sourceCountry"
                                name="sourceCountry"
                                type="select"
                                value={customer.sourceCountry}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, sourceCountry: value})
                                }}
                              >
                                <option>Brasil</option>
                                <option>Estados Unidos</option>
                                <option>Japão</option>
                                <option>Itália</option>
                                <option>Alemanha</option>
                                <option>França</option>
                                <option>Espanha</option>
                                <option>Portugal</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xxl={4}>
                            <FormGroup>
                              <Label
                                for="resident"
                              >
                                Residente:
                              </Label>
                              <Input
                                id="resident"
                                name="resident"
                                type="select"
                              >
                                <option>Sim</option>
                                <option>Não</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="form-align">
                          <Col xxl={3}>
                            <FormGroup floating>
                              <Input
                                id="cpf"
                                name="cpf"
                                placeholder="CPF"
                                type="text"
                                value={customer.cpf}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value.replace(/\D/g,'')
                                  setCustomer({...customer, cpf: value})
                                }}
                                mask="999.999.999-99"
                                tag={InputMask}
                              />
                              <Label for="CPF">
                                CPF
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={3}>
                            {customer.id && 
                            <>
                            <UploadField
                              label="CPF"
                              fieldName="cpfImage"
                              fileUpload={fileUpload}
                              imageUrl={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/cpfImage`}
                            />
                            <a href={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/cpfImage`} target="_blank">
                              Abrir
                            </a>
                            </>
                            } 
                          </Col>
                          <Col xxl={3}>
                            <FormGroup floating>
                              <Input
                                id="rg"
                                name="rg"
                                placeholder="RG"
                                type="text"
                                value={customer.rg}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, rg: value})
                                }}
                              />
                              <Label for="RG">
                                RG
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={3}>
                            {customer.id && 
                            <>
                            <UploadField
                              label="RG"
                              fieldName="rgImage"
                              fileUpload={fileUpload}
                              imageUrl={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/rgImage`}
                            />
                            <a href={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/rgImage`} target="_blank">
                              Abrir
                            </a>
                            </>
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="phone"
                                name="phone"
                                placeholder="Telefone residencial"
                                type="text"
                                value={customer.phone}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value.replace(/\D/g,'')
                                  setCustomer({...customer, phone: value})
                                }}
                                mask="(99)9999-9999"
                                tag={InputMask}
                              />
                              <Label for="phone">
                                Telefone residencial
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="cellphone"
                                name="cellphone"
                                placeholder="Telefone celular"
                                type="text"
                                value={customer.cellphone}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value.replace(/\D/g,'')
                                  setCustomer({...customer, cellphone: value})
                                }}
                                mask="(99)99999-9999"
                                tag={InputMask}
                              />
                              <Label for="cellphone">
                                Telefone celular
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          {/* <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="businessPhone"
                                name="businessPhone"
                                placeholder="Telefone comercial"
                                type="text"
                                value={customer.businessPhone}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value.replace(/\D/g,'')
                                  setCustomer({...customer, businessPhone: value})
                                }}
                                mask="(99)9999-9999"
                                tag={InputMask}
                              />
                              <Label for="businessPhone">
                                Telefone comercial
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col> */}
                        </Row>           
                        <h3 className="form-title">Dados de Endereço</h3>
                        {isLoadingZipcode && (
                          <Oval 
                            height="30"
                            width="30"
                            color='blue'
                            ariaLabel='loading'
                          />
                        )}
                        <Row className="form-align">
                          <Col xxl={3}>                            
                            <FormGroup floating>
                              <Input
                                id="zipcode"
                                name="zipcode"
                                placeholder="CEP"
                                type="text"
                                value={customer.zipcode}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value.replace(/\D/g,'')
                                  setCustomer({...customer, zipcode: value})

                                  if (value.length >= 8) {                                      
                                    const token = UserStorage.getToken()
                                    const correios = new CorreiosService()
                                    setIsLoadingZipcode(true);
                                    correios.searchAddressByZipcode(value, token)
                                    .then((address) => {                                      
                                      setCustomer({
                                        ...customer, 
                                        zipcode: value, 
                                        street: address.street, 
                                        neighborhood: address.neighborhood, 
                                        city: address.city,
                                        state: address.state
                                      })
                                    })
                                    .catch(error => {
                                      console.log('error', error.response.data)
                                      if (error.response.data?.MsgErro) {
                                        setErrorMessage(error.response.data.MsgErro);
                                      }
                                    })      
                                    .finally(() => setIsLoadingZipcode(false))                                                                  
                                  }                                  
                                }}
                              />
                              <Label for="zipcode">
                                CEP
                              </Label>
                            </FormGroup>
                            {' '}                                                        
                          </Col>
                          <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="street"
                                name="street"
                                placeholder="Logradouro"
                                type="text"
                                value={customer.street}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, street: value})
                                }}
                              />
                              <Label for="street">
                                Logradouro
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={2}>
                            <FormGroup floating>
                              <Input
                                id="residentialNumber"
                                name="residentialNumber"
                                placeholder="Número"
                                type="text"
                                value={customer.number}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, number: value})
                                }}
                              />
                              <Label for="residentialNumber">
                                Número
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col xxl={3}>
                            <FormGroup floating>
                              <Input
                                id="complement"
                                name="complement"
                                placeholder="Complemento"
                                type="text"
                                value={customer.complement}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, complement: value})
                                }}
                              />
                              <Label for="complement">
                                Complemento
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="neighborhood"
                                name="neighborhood"
                                placeholder="Bairro"
                                type="text"
                                value={customer.neighborhood}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, neighborhood: value})
                                }}
                              />
                              <Label for="neighborhood">
                                Bairro
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={4}>
                            <FormGroup floating>
                              <Input
                                id="city"
                                name="city"
                                placeholder="Cidade"
                                type="text"
                                value={customer.city}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, city: value})
                                }}
                              />
                              <Label for="city">
                                Cidade
                              </Label>
                            </FormGroup>
                            {' '}
                          </Col>
                          <Col xxl={4}>
                            <FormGroup>
                              <Label 
                                for="state"
                              >
                                Estado:
                              </Label>
                              <Input
                                id="state"
                                name="state"
                                placeholder="Estado"
                                type="select"
                                value={customer.state}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, state: value})
                                }}
                              >
                                <option value="default" disabled>Estado:</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                                <option value="DF">Distrito Federal</option>
                              </Input>
                            </FormGroup>
                            {' '}
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={6}>
                            {customer.id && 
                            <>
                              <UploadField
                                label="Comprovante de Residência"
                                fieldName="addressImage"
                                fileUpload={fileUpload}
                                imageUrl={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/addressImage`}
                              />
                              <a href={`${process.env.REACT_APP_API_URI}/customer/file/${customer.id}/addressImage`} target="_blank">
                                Abrir
                              </a>
                              {/* <ImgsViewer
                                imgs={[
                                  { src: "http://example.com/img1.jpg" },
                                  { src: "http://example.com/img2.png" },
                                ]}
                                currImg={this.state.currImg}
                                isOpen={this.state.viewerIsOpen}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                onClose={this.closeViewer}
                              /> */}
                            </>
                            }
                          </Col>
                        </Row>
                        <h3 className="form-title">Dados Complementares</h3>
                        <Row className="form-align">
                          <Col xxl={4}>
                            <FormGroup>
                              <Label 
                                for="howMeet"
                              >
                                Como nos conheceu:
                              </Label>
                              <Input
                                id="howMeet"
                                name="howMeet"
                                type="select"
                                value={customer.howMeet}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, howMeet: value})
                                }}
                              >
                                <option value="GG">Google</option>
                                <option value="FB">Facebook</option>
                                <option value="IN">Indicação</option>
                                <option value="PN">Portal de notícias</option>
                                <option value="BG">Blog</option>
                                <option value="FR">Fórum</option>
                                <option value="OU">Outro</option>
                              </Input>
                            </FormGroup>
                            {' '}
                          </Col>
                          {customer.id > 0 && (
                          <>
                          <Col xxl={4}>
                            <FormGroup>
                              <Label for="registrationDate">
                                Data de cadastro:
                              </Label>
                              <p>{customer.createdAt.toLocaleDateString('pt-BR')}</p>
                            </FormGroup>
                          </Col>
                          <Col xxl={4}>
                          <FormGroup>
                            <Label for="registrationDate">
                              Hora de cadastro:
                            </Label>
                            <p>{customer.createdAt.toLocaleTimeString('pt-BR')}</p>
                          </FormGroup>
                        </Col>
                        </>
                          )}
                        </Row>
                        {/* <FormGroup floating>
                          <Input
                            id="partnerId"
                            name="partnerId"
                            placeholder="ID do parceiro"
                            type="number"
                          />
                          <Label for="partnerId">
                            ID do parceiro
                          </Label>
                        </FormGroup> */}
                        <Row>
                          <Col xxl={8}>
                            <FormGroup>
                              <Label
                                for="observation"
                              >
                                Observação:
                              </Label>             
                              <Input
                                id="observation"
                                name="observation"
                                type="textarea"
                                bsSize="lg"
                              />                       
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={2}>
                            <FormGroup>
                              <Label
                                for="status"
                              >
                                Status:
                              </Label>
                              <Input
                                id="status"
                                name="status"
                                type="select"
                                value={customer.status}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value
                                  setCustomer({...customer, status: value})
                                }}
                              >
                                <option value={0}>Inativo</option>
                                <option value={1}>Ativo</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <FormGroup className="form-button-spacing">
                      <Button color="primary"type="submit">Salvar</Button>
                      <Button color="danger">Excluir</Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Container>

            {/* <form onSubmit={save} className="form form--horizontal" >
              <div className="form__form-group">
              <span className="form__form-group-label">Nome</span>
                <div className="form__form-group-field">
                  <input
                    name="name"
                    type="text"
                    placeholder="Nome"
                    value={customer.name}
                    onChange={(event) => setCustomer({...customer, name: event.target.value})}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">E-mail</span>
                <div className="form__form-group-field">
                  <input
                    name="email"
                    type="email"
                    placeholder="test@mail.com"
                    value={customer.email}
                    onChange={(event) => setCustomer({...customer, email: event.target.value})}
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Salvar</Button>
              </ButtonToolbar>
            </form> */}

          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
)};

export default CustomerFormPage;