import ApiClient from "./ApiClient"
import urlJoin from 'url-join'

const apiClient = new ApiClient()
export class AuthenticationService {
  public async login(email:string, password:string): Promise<string> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/auth/login`)

    const data: any = { email, password }
    try {
      const res = await apiClient.DoRequest('POST', url, data)
      return res.token
    } catch (error: any) {
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Erro inesperado.')
    }
  }

  public async logout(token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/auth/logout`)

    try {
      const res = await apiClient.DoRequest('POST', url, {}, { Authorization: `Bearer ${token}` })
      return res.token
    } catch (error: any) {
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
    }
  }

  public async forgotPassword(email:string): Promise<boolean> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/auth/forgotPassword`)

    const data: any = { email, type: 'user' }
    try {
      const res = await apiClient.DoRequest('POST', url, data)
      return res.success === true
    } catch (error: any) {
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Erro inesperado.')
    }
  }

  public async recoveryPassword(token: string, email:string, password: string, passwordConfirmation: string): Promise<boolean> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/auth/recoveryPassword`)

    const data: any = { token, email, password, passwordConfirmation, type: 'user' }
    try {
      const res = await apiClient.DoRequest('POST', url, data)
      return res.success === true
    } catch (error: any) {
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Erro inesperado.')
    }
  }
}