import { Currency } from "../currency/Currency"

export class Country {
  public id = 0
  public name = ''
  public code = ''

  public currencies: Currency[] = []

  constructor (data: any = {}) {
    this.id = data.id
    this.name = data.name
    this.code = data.code

    if (data.currencies) {
      for (const currencyData of data.currencies) {
        this.currencies.push(new Currency(currencyData))
      }
    }
  }
}