import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { UserService } from '../../../../services/UserService';
import { User } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';
import { FiArrowLeft } from 'react-icons/fi';

import './index.scss';

const UserFormPage = () => {
  const navigate = useNavigate();
  const params: any = useParams();

  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User>(new User())

  const [errorMessage, setErrorMessage] = useState('')

  const userService = new UserService()
  useEffect(() => {
    const id = parseInt(params.id)
    if (id && id !== 0) {
      loadUser(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const loadUser = async (id: number) => {
    setIsLoading(true);
    const token = UserStorage.getToken()
    const userData = await userService.get(id, token);
    setUser(userData)
    setIsLoading(false);
  }

  const save = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('')

    if (!user.email) {
      setErrorMessage('Digite o e-mail.');
      return ''
    }

    if (!user.name) {
      setErrorMessage('Digite o nome.');
      return ''
    }

    if (user.id === '' || user.id === undefined || (user.password !== undefined && user.password.length > 0)) {
      if (user.password === undefined || user.password.length < 6) {
        setErrorMessage('Digite uma senha.');
        return ''
      }
    }

    if ((user.password !== undefined && user.password.length) >= 6 && user.password !== user.passwordConfirm) {
      setErrorMessage('A confirmação está diferente da senha.');
      return ''
    }

    const token = UserStorage.getToken()
    try {
      await userService.save(user, token)
      navigate('/user')
    }catch (error: any) {
      console.log('e', error.message)
      setErrorMessage(error.message)
    }    
  }

  const deleteUser = async () => {
    console.log('user', user)
    if(window.confirm(`Deseja realmente excluir ${user.name}?`)) {
      const token = UserStorage.getToken()
      await userService.delete(user, token);
      navigate('/user')
    }
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Cadastro de Usuários</h3>
        </Col>
      </Row>
      {isLoading && (
        <p>Carregando...</p>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="form-topside card__title">
                <button className="form-back-button" onClick={() => navigate('/user')}><FiArrowLeft className="FiArrowLeft" /></button>
                <h5 className="bold-text">Usuários</h5>
                <h5 className="subhead">Cadastrar usuário</h5>
              </div>
              <div className="form-error-message-container">
                <span className="form-error-message">{errorMessage}</span>
              </div>
              <Container fluid>
                <Row>
                  <Col xxl={12}>
                    <Form onSubmit={save}>
                      <Row>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Nome"
                              type="text"
                              value={user.name}
                              onChange={(event) => setUser({ ...user, name: event.target.value })}
                            />
                            <Label for="name">
                              Nome
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="email"
                              value={user.email}
                              onChange={(event) => setUser({ ...user, email: event.target.value })}
                            />
                            <Label for="Email">
                              Email
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="password"
                              name="password"
                              placeholder="Senha"
                              type="password"
                              value={user.password}
                              onChange={(event) => setUser({ ...user, password: event.target.value })}
                            />
                            <Label for="password">
                              Senha
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                        <Col xxl={6}>
                          <FormGroup floating>
                            <Input
                              id="passwordConfirm"
                              name="passwordConfirm"
                              placeholder="Confirme sua senha"
                              type="password"
                              value={user.passwordConfirm}
                              onChange={(event) => setUser({ ...user, passwordConfirm: event.target.value })}
                            />
                            <Label for="passwordConfirm">
                              Confirme sua senha
                            </Label>
                          </FormGroup>
                          {' '}
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={2}>
                          <FormGroup>
                            <Label for="status" >
                              Função:
                            </Label>
                            <Input
                              id="role"
                              name="role"
                              type="select"
                              value={user.role}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                setUser({...user, role: value})
                              }}
                            >
                              <option value="admin">Administrador</option>
                              <option value="registration">Cadastro</option>
                              <option value="interbank">Interbancário</option>
                              <option value="financial">Financeiro</option>
                              <option value="money-order">Ordem de Pagamento</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xxl={2}>
                          <FormGroup>
                            <Label for="status" >
                              Status:
                            </Label>
                            <Input
                              id="isActive"
                              name="isActive"
                              type="select"
                              value={user.status === 1 ? 1 : 0}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                setUser({...user, status: parseInt(value)})
                              }}
                            >
                              <option value={1}>Ativo</option>
                              <option value={0}>Inativo</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup className="form-button-spacing">
                        <Button color="primary" type="submit">Salvar</Button>
                        <Button color="danger" onClick={deleteUser}>Excluir</Button>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default UserFormPage;