import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { CustomerService } from '../../../../services/CustomerService';
import { Customer } from '../../../../entities';  
import UserStorage from '../../../../util/userStorage';
import { CSVLink } from 'react-csv';

const CustomerListPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [filter, setFilter] = useState<any>({})

  const customerService = new CustomerService()
  useEffect(() => {
    searchCustomers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchCustomers = async () => {
    setIsLoading(true);
    const token = UserStorage.getToken()    
    const customersData = await customerService.search(filter, token)
    setCustomers(customersData)
    setIsLoading(false);
  }

  const renderCreatedAt = (params: GridCellParams) => {
    const createdAt = new Date(params.row.createdAt)
    return (
      <span>{createdAt.toLocaleDateString('pt-BR')}</span>
    )
  }
  
  const renderActionButtons = (params: GridCellParams) => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          style={{ marginTop: 20 }}
          onClick={() => {
            navigate(`/customer/${params.row.id}`)
          }}
        >
          Editar
        </Button>
      </div>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 400
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 400
    },
    {
      field: 'createdAt',
      headerName: 'Criado',
      width: 300,
      renderCell: renderCreatedAt
    },
    {
      field: '',
      width: 300,
      headerName: 'Ações',
      renderCell: renderActionButtons
    }
  ];
  
  return (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Clientes</h3>
      </Col>
    </Row>
    {isLoading && (
      <p>Carregando...</p>
    )}
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Clientes</h5>
              <Button color="primary" onClick={() => navigate('/customer/0')}>Novo</Button>
              <h5 className="subhead">Listagem de clientes</h5>
            </div>
            <div>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      for="status"
                    >
                      Busca:
                    </Label>
                    <Input
                      id="search"
                      name="search"
                      placeholder="Busca"
                      type="text"
                      value={filter.search}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setFilter({...filter, search: value})
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <FormGroup>
                    <Label
                      for="status"
                    >
                      Status:
                    </Label>
                    <Input
                      id="status"
                      name="status"
                      type="select"
                      value={filter.status}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        setFilter({...filter, status: value})
                      }}
                    >
                      <option value="">Todos</option>
                      <option value={1}>Ativo</option>
                      <option value={0}>Inativo</option>                      
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <Button color="primary" onClick={searchCustomers}>Buscar</Button>
                </Col>
                <Col md={2}></Col>
                <Col md={1}>
                  <CSVLink data={customers} filename='clientes'>
                    <Button color="primary">CSV</Button>  
                  </CSVLink>
                </Col>
              </Row>                        
            </div>
            <div style={{ height: 530, width: '100%' }}>

                <DataGrid
                  rows={customers}
                  columns={columns}
                  pageSize={10}
                  checkboxSelection={false}
                  disableSelectionOnClick                  
                  localeText={{
                    columnHeaderSortIconLabel: 'Ordenar ',
                    footerTotalRows: 'Total de Linhas',
                    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} linhas selecionadas`
                        : `${count.toLocaleString()} linha selecionada`,
                  }}
                />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
)};

export default CustomerListPage;
