import urlJoin from 'url-join'
import { Order } from '../entities/order/Order'
import { OrderStatus } from '../entities/order/OrderStatus'
import UserStorage from '../util/userStorage'
import ApiClient from "./ApiClient"

const apiClient = new ApiClient()

export class OrderService {
  public async search (filter: any, token: string): Promise<Order[]> {
    let url = urlJoin(apiClient.getRestDefaultUri(), `/order?`)
    
    if (filter.statusId) {
      url += 'statusId=' + filter.statusId
    }

    const result = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    const orders: Order[] = []
    for (const order of result.data) {
      orders.push(new Order(order))
    }

    return orders
  }

  public async get (id: number, token: string): Promise<Order> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/order/${id}`)

    const res = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    return new Order(res.order)
  }

  public async add (order: Order): Promise<Order> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/order`)

    const token = await UserStorage.getToken()
    const data = await apiClient.DoRequest('POST', url, order, { Authorization: token })

    return new Order(data.order)
  }

  public async searchStatus (filter: any, token: string): Promise<OrderStatus[]> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/orderStatus`)

    const result = await apiClient.DoRequest('GET', url, {}, { Authorization: token })

    const statusList: OrderStatus[] = []
    for (const order of result.data) {
      statusList.push(new OrderStatus(order))
    }

    return statusList
  }

  public async changeStatus (orderId: number, statusCode: string, paid: number|undefined): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/order/changeStatus`)

    const data: any = {
      orderId,
      statusCode
    }

    if (paid) {
      data.paid = paid
    }

    const token = await UserStorage.getToken()
    await apiClient.DoRequest('POST', url, data, { Authorization: token })
  }
}