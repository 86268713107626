import React from 'react';
import './index.scss';

const HomePage = () => {
    return (
        <section className="dashboard-page-container">
            <div className="dashboard-page-title">
                <h1>Dashboard</h1>
            </div>
            <div className="dashboard-cards-container">
                <div className="dashboard-card">
                    <div className="dashboard-card-title">
                        <h1>Aguardando Confirmação de Pagamento</h1>
                        <h2>Reais</h2>
                    </div>
                    <div className="dashboard-card-info">
                        <p><strong>R$</strong>1.000,00</p>
                        <p><strong>USD</strong>2.000,00</p>
                    </div>
                    <div className="dashboard-card-bank-info">
                    </div>
                </div>

                <div className="dashboard-card">
                    <div className="dashboard-card-title">
                        <h1>Pronto para Envio</h1>
                        <h2>Moeda Estrangeira</h2>
                        <h3>Cobertura</h3>
                    </div>
                    <div className="dashboard-card-info">
                        <p><strong>R$</strong>1.000,00</p>
                        <p><strong>USD</strong>2.500,00</p>
                    </div>
                    <div className="dashboard-card-bank-info">
                        <p>Interbancário</p>
                    </div>
                </div>

                <div className="dashboard-card">
                    <div className="dashboard-card-title">
                        <h1>Envio ao Exterior</h1>
                        <h3>Liquidação das Operações</h3>
                    </div>
                    <div className="dashboard-card-info">
                        <p></p>
                    </div>
                    <div className="dashboard-card-bank-info">
                        <p></p>
                    </div>
                </div>
            </div>

            <div className="dashboard-page-buttons">
                <button>Liquidar</button>
                <button>Envio</button>
            </div>
        </section>
    );
}

export default HomePage;
