export class SendType {
  public id = 0;
  public forWhom = '';
  public reason = '';
  public code = 0;
  public tax = 0;
  public isActive = true;

  constructor (data: any = {}) {
    this.id = data.id;
    this.forWhom = data.forWhom;
    this.reason = data.reason;
    this.code = data.code;
    this.tax = data.tax;
    this.isActive = data.isActive === true
  }
}